<template>
  <div class="article">
    <div class="tbl_wrap">
      <div class="tbl_option">
        <h5>수주 제품 현황</h5>
        <my-local-selectric
          :id="'SearchTypeSelect'"
          :options="search_type_options"
          :watch="search_type_id"
          @changeValue="
            $store.commit('setSearchTypeIdToPlan', $event);
            fetchList();
          "
        >
        </my-local-selectric>
        <div>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="start_date"
              @change="changeStartDate($event)"
            />
          </div>
          <p>~</p>
          <div class="input_text">
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="end_date"
              @change="changeEndDate($event)"
            />
          </div>
        </div>
        <button type="button" class="btn_sub2" @click="fetchList()">
          검색
        </button>
      </div>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 9" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>매출처명</th>
              <th>제품명</th>
              <th>납품수량</th>
              <th>납품 요청일</th>
              <th>수주일</th>
              <th>생산계획일</th>
              <th>납품여부</th>
              <th>매출액</th>
              <th>비고</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, index) in sales_products"
              :key="item.id"
              @click="selectRow(index)"
              :class="{ active: selectedIndex == index }"
            >
              <td class="text_left">
                {{
                  findInfoFromId(
                    companys,
                    findInfoFromId(sales, item.sales_id).company_id,
                  ).name
                }}
              </td>
              <td class="text_left">
                {{ findInfoFromId(products, item.product_id).name }}
              </td>
              <td class="text_right">
                <b>{{ $makeComma(item.quantity) }}</b>
                {{
                  item.box_unit_id == null
                    ? findInfoFromId(
                        units,
                        findInfoFromId(products, item.product_id).stock_unit_id,
                      ).name
                    : findInfoFromId(boxs, item.box_unit_id).name
                }}
              </td>
              <td>
                {{ findInfoFromId(sales, item.sales_id).delivery_date }}
              </td>
              <td>
                {{ findInfoFromId(sales, item.sales_id).input_date }}
              </td>
              <td>
                {{
                  item.plan_start_date != null
                    ? `${item.plan_start_date}~${item.plan_end_date}`
                    : ''
                }}
              </td>
              <td>
                {{ item.output_yn ? 'Y' : 'N' }}
              </td>
              <td class="text_right">
                &#8361; {{ $makeComma(item.total_cost) }}
              </td>
              <td class="text_left">
                {{ findInfoFromId(sales, item.sales_id).detail }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="aside_management_mode" v-if="managementData != null">
      <div class="head">
        <h5>세부정보</h5>
      </div>
      <div class="form" @submit.prevent>
        <form>
          <div class="input_text">
            <label class="require">매출처</label>
            <input
              type="text"
              :value="findInfoFromId(companys, managementData.company_id).name"
              readonly
            />
          </div>
          <div class="input_text">
            <label>납품 요청일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="managementData.delivery_date"
              readonly
            />
          </div>
          <div class="input_text">
            <label>수주일</label>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="managementData.input_date"
              readonly
            />
          </div>
          <div class="input_text input_plan">
            <label>생산 계획</label>

            <button class="btn_sub1" @click="showCalendarModal = true">
              일정확인
            </button>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="managementData.plan_start_date"
              @change="changePlanStartDate($event)"
            />
            <p>~</p>
            <input
              type="date"
              placeholder="YYYY-MM-DD"
              :value="managementData.plan_end_date"
              @change="changePlanEndDate($event)"
            />
          </div>
          <div class="btn_wrap">
            <button class="btn_sub2" type="button" @click="submitForm()">
              저장
            </button>
          </div>
        </form>
      </div>
    </div>
    <calendar-modal
      v-if="showCalendarModal"
      @onclose="showCalendarModal = false"
    ></calendar-modal>
    <div
      class="modal_layer"
      v-if="showCalendarModal"
      @click="showCalendarModal = false"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { yyyymmdd } from '@/utils/func';
import FETCH_MIXIN from '@/mixins/fetch';
import MODAL_MIXIN from '@/mixins/modal';
import SPINNER_MIXIN from '@/mixins/spinner';
import MyLocalSelectric from '@/layouts/components/MyLocalSelectric';
import CalendarModal from '@/views/forms/Plan/6/CalendarModal';
import DRAG_MODAL_MIXIN from '@/mixins/drag_modal';
export default {
  mixins: [FETCH_MIXIN, MODAL_MIXIN, SPINNER_MIXIN, DRAG_MODAL_MIXIN],
  components: {
    MyLocalSelectric,
    CalendarModal,
  },
  data() {
    return {
      search_type_options: [
        { label: '수주 등록일 기준', value: 0 },
        { label: '납품 요청일 기준', value: 1 },
      ],
      showCalendarModal: false,
    };
  },
  computed: {
    ...mapGetters({
      search_type_id: 'getSearchTypeIdFromPlan',
      start_date: 'getStartDateFromPlan',
      end_date: 'getEndDateFromPlan',
      sales: 'getSalesFromPlan',
      managementData: 'getManagementDataFromPlan',
      units: 'getUnitCodes',
      products: 'getProduct',
      companys: 'getCompany',
      selectedIndex: 'getSelectedIndexFromPlan',
      boxs: 'getBoxsNotSorted',
    }),
    sales_products() {
      if (this.sales.length < 1 || this.boxs.length < 1) {
        return [];
      } else {
        let sales = this.lodash.clonedeep(this.sales);
        if (this.search_type_id == 0) {
          sales.sort((a, b) => new Date(a.input_date) - new Date(b.input_date));
        } else {
          sales.sort(
            (a, b) => new Date(a.delivery_date) - new Date(b.delivery_date),
          );
        }
        let sales_products = sales.map(x => x.product_list);

        sales_products = sales_products.reduce((a, b) => a.concat(b));
        sales_products = sales_products.map(x => {
          if (x.box_unit_id != null) {
            const boxUnit = this.findInfoFromId(this.boxs, x.box_unit_id);

            if (boxUnit != undefined) {
              x.total_quantity = this.$decimalMul(
                this.$makeNumber(x.quantity),
                boxUnit.quantity,
              );
            } else {
              x.total_quantity = x.quantity;
            }
          } else {
            x.total_quantity = x.quantity;
          }
          return x;
        });
        return sales_products;
      }
    },
  },

  methods: {
    fetchList() {
      this.selectRow(-1);
      if (this.search_type_id == 1) {
        this.FETCH_WITH_PAYLOAD(
          'FETCH_SALES_TO_PLAN',
          {
            start_date: this.start_date,
            end_date: this.end_date,
          },
          '수주',
        );
      } else {
        this.FETCH_WITH_PAYLOAD(
          'FETCH_SALES_TO_PLAN_SEARCH_BY_INPUT_DATE',
          {
            start_date: this.start_date,
            end_date: this.end_date,
          },
          '수주',
        );
      }
    },
    async submitForm() {
      this.showSpinner();
      const payload = this.lodash.clonedeep(this.managementData);
      await this.$store
        .dispatch('UPDATE_SALES_PRODUCT_PLAN', payload)
        .then(() => {
          this.openOneButtonModal('저장 성공', '생산 계획을 저장하였습니다');
          this.fetchList();
        })
        .catch(() => {
          this.openOneButtonModal(
            '저장 실패',
            '생산 계획 저장 중 오류가 발생하였습니다',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    changePlanStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.managementData.plan_end_date) {
          this.openOneButtonModal(
            '계획기간 오류',
            `계획기간 최후날짜(${this.managementData.plan_end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.managementData.plan_start_date;
        } else {
          this.managementData.plan_start_date = e.target.value;
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },
    changePlanEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.managementData.plan_start_date) {
          this.openOneButtonModal(
            '계획기간 오류',
            `계획기간 최초날짜(${this.managementData.plan_start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.managementData.plan_end_date;
        } else {
          this.managementData.plan_end_date = e.target.value;
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
      }
    },

    selectRow(index) {
      this.$store.commit('setSelectedIndexToPlan', index);
      if (index == -1) {
        this.$store.commit('setManagementDataToPlan', null);
      } else {
        const payload = this.lodash.clonedeep(this.sales_products[index]);
        const sales = this.lodash.clonedeep(
          this.findInfoFromId(this.sales, payload.sales_id),
        );
        payload.input_date = sales.input_date;
        payload.delivery_date = sales.delivery_date;
        payload.company_id = sales.company_id;
        if (
          payload.plan_start_date == undefined ||
          payload.plan_start_date == null
        ) {
          payload.plan_start_date = this.lodash.clonedeep(sales.input_date);
          payload.plan_end_date = this.lodash.clonedeep(sales.delivery_date);
        }

        this.$store.commit('setManagementDataToPlan', payload);
      }
    },
    changeStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.end_date) {
          this.openOneButtonModal(
            '검색기간 오류',
            `검색기간 최후날짜(${this.end_date}) 이후의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.start_date;
        } else {
          this.$store.commit('setStartDateToPlan', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToPlan', yyyymmdd(new Date()));
      }
    },
    changeEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.start_date) {
          this.openOneButtonModal(
            '검색기간 오류',
            `검색기간 최초날짜(${this.start_date}) 이전의<br>날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.end_date;
        } else {
          this.$store.commit('setEndDateToPlan', e.target.value);
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToPlan', yyyymmdd(new Date()));
      }
    },
  },
  async created() {
    if (this.start_date == null) {
      var date = new Date();
      this.$store.commit('setEndDateToPlan', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 14));
      this.$store.commit('setStartDateToPlan', yyyymmdd(date2));
    }

    if (this.units.length < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.companys.length < 1) {
      await this.FETCH('FETCH_COMPANY', '매출처');
    }
    if (this.boxs.length < 1) {
      await this.FETCH('FETCH_BOX', '박스');
    }
    this.FETCH_WITH_PAYLOAD(
      'FETCH_SALES_TO_PLAN_SEARCH_BY_INPUT_DATE',
      {
        start_date: this.start_date,
        end_date: this.end_date,
      },
      '수주',
    );
  },
};
</script>

<style lang="scss" scoped></style>
