<template>
  <div
    id="contents"
    :class="{
      product_plan_manage: tabIndex == 0,
      product_availability: tabIndex == 1,
    }"
  >
    <div class="contents_head">
      <h2>제품 생산 계획 관리</h2>
      <div class="aside" v-if="$screen.width < 1280">
        <aside-selectric
          :id="'tabletAsideSelectric'"
          :watch="tabIndex"
          :commit="'setOpenTabIndexToGumsanManagementPage'"
          :options="[
            { title: '생산 계획 관리', name: 'product_plan_manage' },
            { title: '생산 가능 여부', name: 'product_availability' },
          ]"
        >
        </aside-selectric>
      </div>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">생산 계획 관리</a>
          </li>
          <li :class="{ active: tabIndex == 1 }">
            <a @click="SetOpenTabIndex(1)">생산 가능 여부</a>
          </li>
        </ul>
      </div>
      <plan-managenment-form v-if="tabIndex == 0"></plan-managenment-form>
      <product-availability-form
        v-if="tabIndex == 1"
      ></product-availability-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import PlanManagenmentForm from '@/views/forms/Plan/6/PlanManagementForm';
import ProductAvailabilityForm from '@/views/forms/Plan/6/ProductAvailabilityForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import AsideSelectric from '@/layouts/components/AsideSelectric';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    PlanManagenmentForm,
    ProductAvailabilityForm,
    AsideSelectric,
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromGumsanManagementPage',
    }),
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToGumsanManagementPage',
    }),
  },
  created() {
    if (this.$route.meta.menu_init == true) {
      // this.$store.commit('InitGumsanManagementPage');
      this.$route.meta.menu_init = false;
    }
  },
};
</script>

<style lang="scss">
@import './gumsan_plan.scss';
</style>
